<template>
  <div class="competence">
    <Header back="返回" title="政策结果" index="首页" beforeTitle="政策结果" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select v-model="value" placeholder="请选择省">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择市">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择区">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="value" placeholder="请选择客户来源类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input v-model="input" placeholder="请输入名称关键字"></el-input>
          <el-button class="search">查询</el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="企业名称"></el-table-column>
        <el-table-column prop="place" label="所属区域"></el-table-column>
        <el-table-column prop="type" label="客户来源类型"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="handleEdit(scope.$index, scope.row)">结果</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage3: 5,
      tableData: [
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
          type: "意向客户",
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
        {
          name: "王小虎",
          place: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      input: "",
    };
  },

  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit (index, row) {
      console.log(index, row);
    },
    handleDelete (index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style>
.competence {
  height: 100%;
}
</style>
